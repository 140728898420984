import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useStyles2 } from '@grafana/ui';
import CronJobSchedulerWrapper from 'components/CronJobScheduler/CronJobSchedulerWrapper';
import ContactPointsDropdown from 'components/ContactPointsDropdown';
import { GrafanaTheme2 } from '@grafana/data';
import { css } from '@emotion/react';
import DashboardDropdown from 'components/DashboardDropdown';
import ScheduledReports, { ContactPoint } from 'components/ScheduledReports';

interface TimeRange {
  From: string;
  To: string;
}

export interface Report {
  dashTitle: string;
  dashUID: string;
  scheduledLabel: string;
  timeRange: TimeRange;
  contactPoints: ContactPoint;
  configIdentifier: string;
  scheduleTime?: string;
}


export function OnPeriod(): JSX.Element {
  const s = useStyles2(getStyles);
  const [reports, setReports] = useState<Report[]>([]);

  const fetchReports = async () => {
    try {
      const response = await axios.get('api/plugins/dashboard-reporter-app/resources/list-scheduled-report');
      setReports(response.data || []);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchReports();
  }, []);


  return (
    <div>
      <DashboardDropdown />
      <ContactPointsDropdown />
      <CronJobSchedulerWrapper s={s} fetchReports={fetchReports} reports={reports} />
      <ScheduledReports reports={reports || []} setReports={setReports} />
    </div>
  );
}

export default OnPeriod;


const getStyles = (theme: GrafanaTheme2) => ({
  colorWeak: css`
    color: ${theme.colors.text.secondary};
  `,
  marginTop: css`
    margin-top: ${theme.spacing(3)};
  `,
  marginTopXl: css`
    margin-top: ${theme.spacing(6)};
  `,
  marginLeft: css`
    margin-left: ${theme.spacing(3)};
  `,
  background: css`
    background-color: ${theme.colors.background.canvas};
  `,
  textColor: css`
     color: ${theme.colors.text.primary};
    `,
});
