import * as React from 'react';
import { AppRootProps } from '@grafana/data';
import { PluginPropsContext } from '../../utils/utils.plugin';
import { Routes } from '../Routes';
import { DropdownProvider } from './DropdownProvider';

export class App extends React.PureComponent<AppRootProps> {
  render() {
    return (
      <PluginPropsContext.Provider value={this.props}>
        <DropdownProvider>
          <Routes />
        </DropdownProvider>
      </PluginPropsContext.Provider>
    );
  }
}
