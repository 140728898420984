import React from 'react';
import axios from 'axios';
import { PopUp } from 'helpers/PopUp';
import { Button } from '@grafana/ui';
import CustomTable from 'components/CustomComponents/CustomTable';
import { cronToHumanReadable } from 'helpers/CronToHumanReadable';

interface TimeRange {
    From: string;
    To: string;
}

interface ContactPointSettings {
    addresses: string;
    singleEmail: boolean;
    url: string;
}

export interface ContactPoint {
    label: string;
    name: string;
    settings: ContactPointSettings;
    type: string;
    uid: string;
    value: string;
}

export interface Report {
    dashTitle: string;
    dashUID: string;
    scheduledLabel: string;
    timeRange: TimeRange;
    contactPoints: ContactPoint;
    configIdentifier: string;
}

interface ScheduledReportsProps {
    reports: Report[];
    setReports: (reports: Report[]) => void;
}



export function ScheduledReports({ reports, setReports }: ScheduledReportsProps): JSX.Element {

    const handleRemove = async (configIdentifier: string) => {
        try {
            await axios.delete(`api/plugins/dashboard-reporter-app/resources/remove-scheduled-report?dashUid=${configIdentifier}`);
            const removedReport = reports.find(report => report.configIdentifier === configIdentifier) ? reports.filter(report => report.configIdentifier !== configIdentifier) : [];
            setReports(removedReport)
            PopUp('Scheduled report removed successfully.', true);
        } catch (error) {
            console.error(error);
            PopUp('Error removing report.', false);
        }
    };



    const columns = [
        {
            title: 'Dashboard Title',
            dataIndex: 'dashTitle',
            key: 'dashTitle',
            render: (text: string) => text || 'N/A'
        },
        {
            title: 'Schedule',
            dataIndex: 'scheduleTime',
            key: 'scheduleTime',
            render: (cronExpression: string) => cronToHumanReadable(cronExpression)
        },
        {
            title: 'Time Range',
            dataIndex: 'timeRange',
            key: 'timeRange',
            render: (timeRange: TimeRange) => `${timeRange?.From} to ${timeRange?.To}`
        },
        {
            title: 'Contact Points',
            dataIndex: 'contactPoints',
            key: 'contactPoints',
            render: (contactPoints: ContactPoint) => `${contactPoints?.label} (${contactPoints?.type})`
        },
        {
            title: 'Emails',
            dataIndex: ['contactPoints', 'settings', 'addresses'],
            key: 'emails'
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (record: Report) => (
                <Button onClick={() => handleRemove(record.configIdentifier)}>
                    Remove
                </Button>
            )
        }
    ];


    return (

        <div>
            <CustomTable
                contactPoints={reports || []}
                dataTable={reports || []}
                handleRemove={handleRemove}
                columns={columns}
                propertyToRemove='configIdentifier'
            />
        </div>
    );
}

export default ScheduledReports;
