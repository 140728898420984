import React, { useMemo, useState } from 'react';
import { Field, Select } from '@grafana/ui';
import { SelectableValue } from '@grafana/data';
import Cron from 'react-cron-generator';
import { cx } from '@emotion/css';
import './cron-builder.css';
import { useDropdowns } from 'components/App/DropdownProvider';
import { Report } from "pages/OnPeriod/OnPeriod";
import { cronJobsTimes } from '../../constants';

type CronJobSchedulerProps = {
    s: any;
    reports: Report[];
};

const CronJobScheduler = ({ s, reports }: CronJobSchedulerProps) => {
    const { selectedDashboard, cronExpression, setCronExpression: setContextCronExpression } = useDropdowns();

    const [isCustom, setIsCustom] = useState(false);

    const onCronJobChange = (selectedOption: SelectableValue) => {
        setContextCronExpression(selectedOption.value);
        setIsCustom(selectedOption.label === 'Custom');

    };

    const handleCronChange = (newCron: string) => {
        setContextCronExpression(newCron);
    };

    const filteredCronJobsTimes = useMemo(() => {
        const dashboardReports = reports.filter((el) => el.dashUID === selectedDashboard?.value);
        const scheduledCronTimes = dashboardReports.map((report) => report?.scheduleTime);
        const filteredOptions = cronJobsTimes.filter(option => !scheduledCronTimes.includes(option.value));

        return filteredOptions;
    }, [reports, selectedDashboard]);

    return (
        <div className={cx(s.background, s.padding)}>
            <Field className={s.marginTop} label="Select Report Schedule" description="Choose how often the report should be generated.">
                <Select
                    width={60}
                    options={filteredCronJobsTimes}
                    value={cronExpression}
                    onChange={onCronJobChange}
                    placeholder='Select Schedule'
                    disabled={selectedDashboard === null}
                />
            </Field>

            {isCustom ? (
                <Field label="Custom Schedule" className={s.marginTop}>
                    <div className={s.cronContainer}>
                        <Cron
                            onChange={handleCronChange}
                            value={cronExpression === null ? '' : cronExpression}
                            showResultText={true}
                            showResultCron={false}
                        />
                    </div>
                </Field>
            ) : null}
        </div>
    );
};

export default CronJobScheduler;

