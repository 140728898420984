import React, { createContext, useState, useEffect, useContext, useMemo, ReactNode } from 'react';
import axios from 'axios';
import { ContactPoint } from 'components/ScheduledReports';
import { SelectOption } from 'pages/OnDemand';


// Define the shape of the context value
interface DropdownContextType {
    dashboardOptions: SelectOption[];
    emailContactPoints: ContactPoint[];
    webhookContactPoints: ContactPoint[];
    selectedDashboard: SelectOption | null;
    selectedContactPoint: ContactPoint | null;
    setSelectedDashboard: (value: SelectOption | null) => void;
    setSelectedContactPoint: (value: ContactPoint | null) => void;
    setWebhookContactPoints: (value: ContactPoint[]) => void;
    fetchContactPoints: () => void;
    cronExpression: string | null;
    setCronExpression: (value: string | null) => void;
    error: any;
}

const DropdownContext = createContext<DropdownContextType>({
    dashboardOptions: [],
    emailContactPoints: [],
    webhookContactPoints: [],
    selectedDashboard: null,
    selectedContactPoint: null,
    setSelectedDashboard: () => { },
    setSelectedContactPoint: () => { },
    fetchContactPoints: () => { },
    setWebhookContactPoints: () => { },
    cronExpression: null,
    setCronExpression: () => { },
    error: null,
});


export const useDropdowns = () => {
    const context = useContext(DropdownContext);
    if (!context) {
        throw new Error('useDropdowns must be used within a DropdownProvider');
    }
    return context;
};

export const DropdownProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    // Dashboards dropdown
    const [dashboardOptions, setDashboardOptions] = useState<SelectOption[]>([]);
    const [selectedDashboard, setSelectedDashboard] = useState<SelectOption | null>(null);

    // Contact points
    const [emailContactPoints, setEmailContactPoints] = useState<ContactPoint[]>([]);
    const [webhookContactPoints, setWebhookContactPoints] = useState<ContactPoint[]>([]);

    //Contact point dropdown
    const [selectedContactPoint, setSelectedContactPoint] = useState<ContactPoint | null>(null);
    const [error, setError] = useState<any>(null);

    // Report schedule Contact points
    const [cronExpression, setCronExpression] = useState<string | null>(null);

    const fetchDashboards = async () => {
        try {
            const response = await axios.get('api/plugins/dashboard-reporter-app/resources/search-dashboards');
            const dashboards = response.data.map((dashboard: any) => ({
                label: dashboard.title,
                value: dashboard.uid,
            }));
            setDashboardOptions(dashboards);
        } catch (err) {
            setError(err);
        }
    };

    const fetchContactPoints = async () => {
        try {
            const response = await axios.get<ContactPoint[]>('api/v1/provisioning/contact-points');
            const contactPoints = response.data;

            const emailContactPoints = contactPoints
                .filter((contactPoint) => contactPoint.type === 'email')
                .map((contactPoint) => ({
                    ...contactPoint,
                    label: contactPoint.name,
                    value: contactPoint.uid,
                    addresses: contactPoint.settings.addresses,
                }));

            const webhookContactPoints = contactPoints
                .filter((contactPoint) => contactPoint.type === 'webhook')
                .map((contactPoint) => {
                    const { url } = contactPoint.settings;
                    const dashboardName = new URLSearchParams(url || '').get('dashName') || '';
                    const emailAddresses = emailContactPoints.find(email => email.label === contactPoint.name)?.addresses || '';
                    return {
                        ...contactPoint,
                        dashboardName,
                        emailAddresses,
                    };
                });

            setEmailContactPoints(emailContactPoints);
            setWebhookContactPoints(webhookContactPoints);
        } catch (err) {
            setError(err);
        }
    };


    useEffect(() => {
        const fetchData = async () => {
            await Promise.all([fetchDashboards(), fetchContactPoints()]);
        };
        fetchData();
    }, []);

    const value = useMemo(() => ({
        dashboardOptions,
        emailContactPoints,
        webhookContactPoints,
        selectedDashboard,
        selectedContactPoint,
        setSelectedDashboard,
        setSelectedContactPoint,
        fetchContactPoints,
        setWebhookContactPoints,
        cronExpression,
        setCronExpression,
        error,
    }), [
        dashboardOptions,
        emailContactPoints,
        webhookContactPoints,
        selectedDashboard,
        selectedContactPoint,
        setSelectedDashboard,
        setCronExpression,
        cronExpression,
        error,
    ]);

    return (
        <DropdownContext.Provider value={value}>
            {children}
        </DropdownContext.Provider>
    );
};
