// @ts-ignore
import appEvents from 'grafana/app/core/app_events';
import { AppEvents } from '@grafana/data';


export const PopUp = (message: string, success: boolean) => {
    if (success) {
        appEvents.emit(AppEvents.alertSuccess, [message]);
    } else {
        appEvents.emit(AppEvents.alertError, [message]);
    }
}
