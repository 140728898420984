import React from 'react';
import { PopUp } from 'helpers/PopUp';
import { Button } from '@grafana/ui';
import DashboardDropdown from 'components/DashboardDropdown';
import { timeZone } from 'helpers/helpers';
import { useDropdowns } from 'components/App/DropdownProvider';



export interface SelectOption {
    label: string;
    value: string;
}


export function OnDemand(): JSX.Element {
    const { selectedDashboard, setSelectedDashboard } = useDropdowns();

    function generateReport() {
        try {
            const reportUrl = `api/plugins/dashboard-reporter-app/resources/report?dashUid=${selectedDashboard?.value}&timeZone=${timeZone}`;
            // Open the URL in a new tab
            window.open(reportUrl, '_blank')
            PopUp('Report generated successfully.', true);
            setSelectedDashboard(null);
        } catch (error) {
            PopUp('Error generating report.', false);
            console.error('Error generating report:', error);
        }
    }

    return (
        <div>
            <DashboardDropdown />
            <Button
                variant="primary"
                onClick={generateReport}
            >
                Generate Report
            </Button>
        </div>
    );
}

export default OnDemand;
