import React from 'react';
import { Pagination } from 'antd';
import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';
import { css, cx } from '@emotion/css';

interface PaginationControlsProps {
    currentPage: number;
    pageSize: number;
    totalItems: number;
    onPageChange: (page: number, pageSize?: number) => void;
}

export function PaginationControls({
    currentPage,
    pageSize,
    totalItems,
    onPageChange,
}: PaginationControlsProps): JSX.Element {
    const s = useStyles2(getStyles);

    return (
        <div className={cx(s.paginationContainer)}>
            <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={totalItems}
                onChange={onPageChange}
                // showSizeChanger
                pageSizeOptions={['5', '10', '20', '30', '50']}
                className={cx(s.pagination)}
            />
        </div>
    );
}

export default PaginationControls;

const getStyles = (theme: GrafanaTheme2) => ({
    paginationContainer: css`
        display: flex;
        justify-content: center;
        margin: ${theme.spacing(2)} 0; /* Adjust margin as needed */
    `,
    pagination: css`
        .ant-pagination-item {
            border: 1px solid ${theme.colors.text.secondary}; /* Border color for items */
            border-radius: 5px; /* Rounded corners */
            color: ${theme.colors.text.secondary}; /* Text color */
            background: ${theme.colors.background.secondary}; /* Background color for items */
            transition: background-color 0.3s, border-color 0.3s; /* Smooth transition for hover/active */
        }

        .ant-pagination-item-active a {
            color: ${theme.colors.text.secondary} !important; /* Text color for active page */
        }

        .ant-pagination-prev,
        .ant-pagination-next {
            color: ${theme.colors.text.secondary}; /* Arrow color */
        }

        .ant-pagination-item-link {
            color: ${theme.colors.text.secondary} !important; 

        }

        .ant-pagination-disabled {
            color: ${theme.colors.text.disabled}; /* Disabled item color */
        }

        .ant-select {
            border-radius: 5px; /* Rounded corners */
            background: red !important; 
            color:red !important; 
            border: 1px solid ${theme.colors.text.secondary}; /* Border color */
        }

        #dropdown
        .ant-select-selector {
            border-radius: 5px; /* Rounded corners */
            background: red !important; 
            color: ${theme.colors.text.secondary};
            border: 1px solid ${theme.colors.text.secondary}; /* Border color */
        }

        .ant-select-item {
            border-radius: 5px; /* Rounded corners */
            background: red !important;
            color: ${theme.colors.text.secondary};
            border: 1px solid ${theme.colors.text.secondary}; /* Border color */
        }

        .ant-select-single .ant-select-selector {
            border: 1px solid ${theme.colors.text.secondary}; /* Border color */
        }

        .ant-select-open .ant-select-selector {
            border-color: ${theme.colors.text.secondary}; /* Border color when open */
        }

        .ant-select-item-option-content {
            color: ${theme.colors.text.secondary}; /* Text color in dropdown */
        }

        .ant-select-item-option-selected {
            background-color: ${theme.colors.background.secondary}; /* Selected item background */
            color: ${theme.colors.text.secondary}; /* Selected item text color */
        }

        .ant-select-arrow {
            color: ${theme.colors.text.secondary}; /* Arrow color */
        }
    `,
});
