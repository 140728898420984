import React from "react";
import CronJobScheduler from "./CronJobScheduler";
import { PopUp } from "helpers/PopUp";
import { Button } from "@grafana/ui";
import axios from "axios";
import { timeZone } from "helpers/helpers";
import { useDropdowns } from "components/App/DropdownProvider";
import { Report } from "pages/OnPeriod/OnPeriod";

interface CronJobSchedulerWrapperProps {
    s: any;
    fetchReports: () => void;
    reports: Report[];
}


export const CronJobSchedulerWrapper = ({ s, fetchReports, reports }: CronJobSchedulerWrapperProps) => {
    const { selectedDashboard, setSelectedDashboard, setSelectedContactPoint, selectedContactPoint, cronExpression, setCronExpression } = useDropdowns();


    const scheduleReport = async () => {
        if (!cronExpression) {
            return PopUp('Please select report schedule.', false);
        }

        if (!selectedDashboard?.value) {
            return PopUp('Please select dashboard.', false);
        }

        if (!selectedContactPoint?.value) {
            return selectedContactPoint?.uid === "" ?
                PopUp('Please check contact point configuration.', false) :
                PopUp('Please select contact point.', false);
        }



        const cronParts = cronExpression.split(' ');
        const cronExpressionSliced = cronParts.slice(0, 5).join(' ');

        const postData = {
            dashUid: selectedDashboard.value,
            dashTitle: selectedDashboard.label,
            scheduleTime: cronExpressionSliced,
            contactPoints: selectedContactPoint,
            timeZone: timeZone
        };

        try {
            await axios.post(
                'api/plugins/dashboard-reporter-app/resources/schedule-report',
                postData
            );
            fetchReports();
            PopUp('Report scheduled successfully....', true);
            setSelectedDashboard(null);
            setSelectedContactPoint(null);
            setCronExpression(null);
        } catch (error) {
            PopUp('Error scheduling report....', false);
            console.error('Error scheduling report:', error);
        }
    };

    return (
        <div className={s.marginTop}>

            <CronJobScheduler s={s} reports={reports} />
            <Button
                onClick={scheduleReport}
                variant="primary"
            >
                Schedule Report
            </Button>
        </div>
    );
}


export default CronJobSchedulerWrapper;
