import React from 'react';
import axios from 'axios';
import { PopUp } from 'helpers/PopUp';
import { Button } from '@grafana/ui';
import ContactPointsDropdown from 'components/ContactPointsDropdown';
import DashboardDropdown from 'components/DashboardDropdown';
import ContactPointsReport from 'components/ContactPointsReport';
import { timeZone } from 'helpers/helpers';
import { useDropdowns } from 'components/App/DropdownProvider';


interface ContactPointSettings {
    addresses: string;
    singleEmail: boolean;
    url: string;
}

export interface ContactPoint {
    label: string;
    name: string;
    settings: ContactPointSettings;
    type: string;
    uid: string;
    value: string;
    addresses: string;
}

export function OnAlert(): JSX.Element {
    const { selectedContactPoint, selectedDashboard, fetchContactPoints, webhookContactPoints, setWebhookContactPoints,
        setSelectedDashboard, setSelectedContactPoint } = useDropdowns();

    const addReportContactPoint = async () => {
        if (!selectedDashboard?.value) {
            return PopUp('Please select dashboard.', false);
        }

        if (!selectedContactPoint?.value) {
            return selectedContactPoint?.uid === "" ?
                PopUp('Please check contact point configuration.', false) :
                PopUp('Please select contact point.', false);
        }

        let updatedContactPointData = {
            name: selectedContactPoint.name,
            type: "webhook",
            settings: {
                httpMethod: "POST",
                //contactPointUid: we call on BE contact point to get Email address, dash name so we can display on the the table...
                url: `https://historian.dewesoft.com/api/plugins/dashboard-reporter-app/resources/alert-report?dashUid=${selectedDashboard.value}&contactPointUid=${selectedContactPoint.uid}&dashName=${selectedDashboard.label}&timeZone=${timeZone}`,
                username: "admin",
                password: "password"
            },
            disableResolveMessage: false
        }


        try {
            const response = await axios.post("api/v1/provisioning/contact-points", updatedContactPointData, {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Disable-Provenance': 'true'
                }
            });
            PopUp('New contact point added successfully.', true);
            fetchContactPoints();
            setSelectedContactPoint(null);
            setSelectedDashboard(null);
            console.log('New contact point added successfully:', response);
        } catch (error) {
            PopUp('Error adding new contact point.', false);
            console.error('Error adding new contact point:', error);
        }
    }

    console.log(process.env.GF_RENDERING_CALLBACK_URL, 'process.env.GF_RENDERING_CALLBACK_URL');


    return (
        <div>
            <DashboardDropdown />
            <ContactPointsDropdown webhookContactPoints={webhookContactPoints} />
            <Button
                onClick={addReportContactPoint}
                variant="primary"
            >
                Add report to concat point
            </Button>

            <ContactPointsReport webhookContactPoints={webhookContactPoints} setWebhookContactPoints={setWebhookContactPoints} fetchContactPoints={fetchContactPoints} ></ContactPointsReport>


        </div>
    );
}

export default OnAlert;
