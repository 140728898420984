import React, { useMemo } from 'react';
import { Field, Select } from '@grafana/ui';
import { ContactPoint } from './ScheduledReports';
import { useDropdowns } from './App/DropdownProvider';

export interface SelectOption {
    label: string;
    value: string;
}

interface ContactPointsDropdownProps {
    webhookContactPoints?: ContactPoint[];
}

const ContactPointsDropdown = ({ webhookContactPoints }: ContactPointsDropdownProps) => {
    const { emailContactPoints, setSelectedContactPoint, selectedContactPoint, selectedDashboard } = useDropdowns()

    const saveContactPoint = (contactPoint: ContactPoint) => {
        setSelectedContactPoint(contactPoint);
    }

    const filteredEmailContactPoints = useMemo(() => {
        // Get all dashName and contactPointUid from webhookContactPoints
        const existingDashboardContactPoints = webhookContactPoints?.map((contactPoint) => {
            const urlObj = new URL(contactPoint.settings.url);
            const dashName = urlObj.searchParams.get('dashName');
            const contactPointUid = urlObj.searchParams.get('contactPointUid');

            return { dashName, contactPointUid };
        });

        // Filter emailContactPoints to exclude those already used in the selectedDashboard
        const filteredOptions = emailContactPoints?.filter((emailPoint) => {
            return !existingDashboardContactPoints?.some(existingPoint =>
                existingPoint.contactPointUid === emailPoint.uid &&
                existingPoint.dashName === selectedDashboard?.label
            );
        });

        return filteredOptions?.map(contactPoint => ({
            ...contactPoint,
            label: contactPoint.name,
            value: contactPoint.uid
        }));
    }, [webhookContactPoints, emailContactPoints, selectedDashboard]);

    return (
        <div>
            <Field
                label="Select Contact Points"
                description="Select the contact points to send the report.">
                <Select
                    key={selectedDashboard?.value}
                    width={60}
                    options={filteredEmailContactPoints}
                    value={selectedContactPoint?.value}
                    onChange={(value) => { saveContactPoint(value as ContactPoint) }}
                    disabled={selectedDashboard === null}
                />
            </Field>
        </div>
    );
};

export default ContactPointsDropdown;

