export function cronToHumanReadable(cronExpression: string): string {
    const [minute, hour, dayOfMonth, month, dayOfWeek] = cronExpression.split(' ');

    let result = '';

    // Handle minute
    if (minute === '*' && hour === '*' && dayOfMonth === '*' && month === '*' && dayOfWeek === '*') {
        result = 'every minute';
    } else if (minute === '0' && hour === '*' && dayOfMonth === '*' && month === '*' && dayOfWeek === '*') {
        result = 'every hour';
    } else if (minute === '0' && hour !== '*' && dayOfMonth === '*' && month === '*' && dayOfWeek === '*') {
        result = `at ${hour} o'clock every day`;
    } else if (minute === '0' && hour === '*' && dayOfMonth !== '*' && month === '*' && dayOfWeek === '*') {
        result = `at midnight on day ${dayOfMonth}`;
    } else if (minute === '0' && hour === '*' && dayOfMonth === '*' && month !== '*' && dayOfWeek === '*') {
        result = `at midnight every ${month}`;
    } else if (minute === '0' && hour === '*' && dayOfMonth === '*' && month === '*' && dayOfWeek !== '*') {
        result = `at midnight on ${dayOfWeek}`;
    } else if (minute === '0' && hour === '*' && dayOfMonth === '*' && month === '*' && dayOfWeek === '*') {
        result = 'daily at midnight';
    } else {
        result = 'custom schedule';
    }

    return result;
}
