import React from 'react';
import { Field, Select } from '@grafana/ui';

import { SelectableValue } from '@grafana/data';
import { useDropdowns } from './App/DropdownProvider';

const DashboardDropdown: React.FC = () => {
    const { dashboardOptions, selectedDashboard, setSelectedDashboard, setCronExpression } = useDropdowns();

    const handleDashboardChange = (selected: SelectableValue<string>) => {
        const newSelectedDashboard = {
            label: selected.label!,
            value: selected.value!,
        };
        setSelectedDashboard(newSelectedDashboard);
        setCronExpression(null);
    };

    return (
        <div>
            <Field
                label="Select Dashboard"
                description="Select the dashboard you want to generate a report for."
            >
                <Select
                    key={selectedDashboard?.value}
                    width={60}
                    options={dashboardOptions}
                    onChange={handleDashboardChange}
                    placeholder="Select a dashboard"
                    value={dashboardOptions.find(option => option.value === selectedDashboard?.value)}
                />
            </Field>
        </div>
    );
};

export default DashboardDropdown;
